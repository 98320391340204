import { createSlice } from "@reduxjs/toolkit"
import { setAuthToken } from "../../../services/auth"
import {
  setAmplitudeUserId,
  setAmplitudeUserProperties,
} from "trackers/amplitude"

const defaultUser = {
  id: -1,
  first_name: "",
  last_name: "",
  username: "",
  email: "",
  occupation: "",
  photo: "",
  phone: "",
  notifications: [],
}

const session = createSlice({
  name: "session",
  initialState: {
    isAuthenticated: false,
    token: "",
    error: "",
    user: JSON.parse(JSON.stringify(defaultUser)),
  },
  reducers: {
    loginSuccess: (state, action) => {
      state.isAuthenticated = true
      state.token = action.payload.key
      setAuthToken(action.payload.key)
    },
    loginFailed: () => {},
    logoutSuccess: () => {},
    logoutFailed: () => {},
    updateAccount: (state, action) => {
      state.user = action.payload
      if (action.payload.username) {
        setAmplitudeUserId(action.payload.username)
        setAmplitudeUserProperties(action.payload)
      }
    },
    resetAccount: state => {
      state.user = JSON.parse(JSON.stringify(defaultUser))
      state.isAuthenticated = false
      state.token = ""
      setAuthToken("")
      setAmplitudeUserId("")
      setAmplitudeUserProperties({})
    },
  },
})

export const {
  loginSuccess,
  loginFailed,
  logoutSuccess,
  logoutFailed,
  updateAccount,
  resetAccount,
} = session.actions

export default session.reducer
