import { toast } from "react-toastify"

export const isProduction =
  process.env.GATSBY_PRODUCTION === "TRUE" ? true : false

export const googleClientId =
  "18634575833-tbr9j9tg5flndpam5hrlhlm0dj75jiv9.apps.googleusercontent.com"

export const isBrowser = () => typeof window !== "undefined"

export const getCurrentURL = () => {
  if (isBrowser()) {
    return window.location.href
  }
}

export const getScreenWidth = () => {
  if (isBrowser()) {
    return Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.documentElement.clientWidth
    )
  }
}

export const formatMoney = nominal => {
  if (nominal === 0) {
    return "0"
  }
  let result = ""
  let counter = 0
  while (nominal > 0) {
    if (counter > 0 && counter % 3 == 0) {
      result = "." + result
    }
    result = String(nominal % 10) + result
    nominal = Math.floor(nominal / 10)
    counter += 1
  }
  return result
}

export const getParameterByName = name => {
  let url = isBrowser() && window.location.href
  name = name.replace(/[\[\]]/g, "\\$&")
  let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ""
  return decodeURIComponent(results[2].replace(/\+/g, " "))
}

export const redirectTo = url => {
  isBrowser() && window.location.replace(url)
}

export const redirectWithHistory = url => {
  isBrowser() && (window.location.href = url)
}

export const trimDescription = description => {
  const screenWidth = getScreenWidth()
  const trimmedDescription =
    screenWidth > 1200
      ? description.slice(0, 248)
      : screenWidth > 900
      ? description.slice(0, 128)
      : screenWidth > 840
      ? description.slice(0, 70)
      : screenWidth > 800
      ? description.slice(0, 40)
      : screenWidth > 320
      ? description.slice(0, 120)
      : description.slice(0, 70)
  return trimmedDescription + "..."
}

export const getProgress = (date1, date2) => {
  let result = (new Date() - date1) / (date2 - date1)
  result = Math.round(result * 100)
  result = Math.max(result, 0)
  result = Math.min(result, 100)
  return result
}

export const formatDate = value => {
  let date = new Date(value)
  const day = date.toLocaleString("default", { day: "2-digit" })
  const month = date.toLocaleString("default", { month: "short" })
  const year = date.toLocaleString("default", { year: "numeric" })
  return day + "-" + month + "-" + year
}

export const formatDateTime = value => {
  var m = new Date(value)
  return (
    m.toLocaleString("default", { day: "2-digit" }) +
    "-" +
    m.toLocaleString("default", { month: "short" }) +
    "-" +
    m.toLocaleString("default", { year: "numeric" }) +
    " " +
    m.toLocaleString("default", { hour: "2-digit", hour12: false }) +
    ":" +
    m.toLocaleString("default", { minute: "numeric" }) +
    ":" +
    m.toLocaleString("default", { second: "numeric" })
  )
}

export const checkError = data => {
  let errMsg = ""
  if (typeof data == "object") {
    for (const prop in data) {
      const errData = data[prop]
      if (typeof errData === "string") {
        errMsg += errData
      } else if (errData?.join) {
        errMsg += errData.join(" ")
      }
    }
  }
  if (errMsg === "") {
    errMsg = "An error occured. Please try again later"
  }
  toast.error(errMsg)
}

export const handleAJAX = async (ajax, onSuccess, onError, rethrow = false) => {
  try {
    const resp = await ajax()
    if (onSuccess) {
      onSuccess()
    }
    return resp
  } catch (err) {
    checkError(err.response?.data)
    if (onError) {
      onError()
    }
    if (rethrow) {
      throw err
    }
  }
  return null
}

export const isPastDate = date => {
  date.setHours(23, 59, 59)
  return date < new Date()
}

export const makeid = length => {
  const result = []
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
  const charactersLength = characters.length
  for (let i = 0; i < length; ++i) {
    result.push(characters.charAt(Math.floor(Math.random() * charactersLength)))
  }
  return result.join("")
}
