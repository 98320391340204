// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-activate-js": () => import("./../../../src/pages/account/activate.js" /* webpackChunkName: "component---src-pages-account-activate-js" */),
  "component---src-pages-account-password-reset-js": () => import("./../../../src/pages/account/password/reset.js" /* webpackChunkName: "component---src-pages-account-password-reset-js" */),
  "component---src-pages-classes-js": () => import("./../../../src/pages/classes.js" /* webpackChunkName: "component---src-pages-classes-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-create-class-js": () => import("./../../../src/pages/create-class.js" /* webpackChunkName: "component---src-pages-create-class-js" */),
  "component---src-pages-dashboard-index-js": () => import("./../../../src/pages/dashboard/index.js" /* webpackChunkName: "component---src-pages-dashboard-index-js" */),
  "component---src-pages-dashboard-kelas-index-js": () => import("./../../../src/pages/dashboard/kelas/index.js" /* webpackChunkName: "component---src-pages-dashboard-kelas-index-js" */),
  "component---src-pages-dashboard-kelas-wishlist-js": () => import("./../../../src/pages/dashboard/kelas/wishlist.js" /* webpackChunkName: "component---src-pages-dashboard-kelas-wishlist-js" */),
  "component---src-pages-dashboard-pengaturan-js": () => import("./../../../src/pages/dashboard/pengaturan.js" /* webpackChunkName: "component---src-pages-dashboard-pengaturan-js" */),
  "component---src-pages-dashboard-transaksi-js": () => import("./../../../src/pages/dashboard/transaksi.js" /* webpackChunkName: "component---src-pages-dashboard-transaksi-js" */),
  "component---src-pages-edit-profile-js": () => import("./../../../src/pages/edit-profile.js" /* webpackChunkName: "component---src-pages-edit-profile-js" */),
  "component---src-pages-forget-password-js": () => import("./../../../src/pages/forget-password.js" /* webpackChunkName: "component---src-pages-forget-password-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-pay-js": () => import("./../../../src/pages/pay.js" /* webpackChunkName: "component---src-pages-pay-js" */),
  "component---src-pages-payment-error-js": () => import("./../../../src/pages/payment/error.js" /* webpackChunkName: "component---src-pages-payment-error-js" */),
  "component---src-pages-payment-finish-js": () => import("./../../../src/pages/payment/finish.js" /* webpackChunkName: "component---src-pages-payment-finish-js" */),
  "component---src-pages-payment-index-js": () => import("./../../../src/pages/payment/index.js" /* webpackChunkName: "component---src-pages-payment-index-js" */),
  "component---src-pages-payment-unfinish-js": () => import("./../../../src/pages/payment/unfinish.js" /* webpackChunkName: "component---src-pages-payment-unfinish-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-tamansiswa-js": () => import("./../../../src/pages/tamansiswa.js" /* webpackChunkName: "component---src-pages-tamansiswa-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-pages-tutor-dashboard-js": () => import("./../../../src/pages/tutor-dashboard.js" /* webpackChunkName: "component---src-pages-tutor-dashboard-js" */),
  "component---src-pages-tutor-invitation-js": () => import("./../../../src/pages/tutor-invitation.js" /* webpackChunkName: "component---src-pages-tutor-invitation-js" */),
  "component---src-pages-verification-js": () => import("./../../../src/pages/verification.js" /* webpackChunkName: "component---src-pages-verification-js" */),
  "component---src-pages-wip-preview-test-payment-js": () => import("./../../../src/pages/wip-preview/test-payment.js" /* webpackChunkName: "component---src-pages-wip-preview-test-payment-js" */),
  "component---src-templates-edit-kelas-js": () => import("./../../../src/templates/edit-kelas.js" /* webpackChunkName: "component---src-templates-edit-kelas-js" */),
  "component---src-templates-kelas-js": () => import("./../../../src/templates/kelas.js" /* webpackChunkName: "component---src-templates-kelas-js" */),
  "component---src-templates-order-kelas-js": () => import("./../../../src/templates/order-kelas.js" /* webpackChunkName: "component---src-templates-order-kelas-js" */)
}

