import axios from "axios"

export const googleClientId =
  "18634575833-tbr9j9tg5flndpam5hrlhlm0dj75jiv9.apps.googleusercontent.com"

export const setAuthToken = token => {
  if (token) {
    axios.defaults.headers.common.Authorization = `Token ${token}`
  } else {
    axios.defaults.headers.common.Authorization = ""
  }
}

export const apiURL = process.env.GATSBY_API_URL
  ? process.env.GATSBY_API_URL
  : "https://staging-api.tamanschool.asdftech.id"

export const frontendUrl = process.env.GATSBY_FRONTEND_URL
  ? process.env.GATSBY_FRONTEND_URL
  : "http://localhost:9000"

export const login = data => axios.post(`${apiURL}/dj-rest-auth/login/`, data)

export const logout = () => axios.post(`${apiURL}/dj-rest-auth/logout/`)

export const register = data =>
  axios.post(`${apiURL}/dj-rest-auth/registration/`, data)

export const loginGoogle = data =>
  axios.post(`${apiURL}/dj-rest-auth/google/`, data)

export const resendVerificationEmail = email =>
  axios.post(`${apiURL}/acc-verification/`, {
    email: email,
  })

export const editProfile = data =>
  axios.patch(`${apiURL}/user/${data.get("id")}/`, data)

export const verifyEmail = code =>
  axios.get(`${apiURL}/acc-verification/?code=${code}`)

export const setPassword = data =>
  axios.post(`${apiURL}/dj-rest-auth/password/change/`, data)

export const forgetPassword = data => {
  return axios.post(`${apiURL}/dj-rest-auth/password/reset/`, {
    email: data,
  })
}

export const confirmForgetPassword = (
  new_password1,
  new_password2,
  uid,
  token
) => {
  return axios.post(`${apiURL}/dj-rest-auth/password/reset/confirm/`, {
    new_password1: new_password1,
    new_password2: new_password2,
    token: token,
    uid: uid,
  })
}
