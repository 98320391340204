import { isBrowser, makeid } from "services/utils"
import amplitude from "amplitude-js"

export const initAmplitude = () => {
  amplitude
    .getInstance()
    .init(
      process.env.GATSBY_AMPLITUDE_API_KEY
        ? process.env.GATSBY_AMPLITUDE_API_KEY
        : "1a1aafbe8cf11c35ddf54c22047869bd"
    )
}

export const getDeviceId = () => {
  if (isBrowser() && window.localStorage.getItem("tamanid")) {
    return window.localStorage.getItem("tamanid")
  } else {
    return ""
  }
}

export const initDeviceId = () => {
  if (isBrowser() && !getDeviceId()) {
    window.localStorage.setItem("tamanid", makeid(30))
  }
}

export const setAmplitudeUserDevice = () => {
  if (!getDeviceId()) {
    initDeviceId()
  }
  amplitude.getInstance().setDeviceId(getDeviceId())
}

export const setAmplitudeUserId = userId => {
  amplitude.getInstance().setUserId(userId)
}

export const setAmplitudeUserProperties = properties => {
  amplitude.getInstance().setUserProperties(properties)
}

export const sendAmplitudeData = (eventType, eventProperties) => {
  amplitude.getInstance().logEvent(eventType, eventProperties)
}

// CLASSES PAGE
export const trackClassesViewed = () => {
  sendAmplitudeData("CLASSES_PAGE_VIEWED")
}

// CLASS PAGE
export const trackClassViewed = classInfo => {
  if (classInfo?.slug) {
    sendAmplitudeData("CLASS_VIEWED", {
      id: classInfo.id,
      slug: classInfo.slug,
      name: classInfo.name,
    })
  }
}

export const trackClassError = slug => {
  sendAmplitudeData("OPEN_CLASS_PAGE_ERROR", {
    slug: slug,
  })
}

export const trackClassJoinButton = classInfo => {
  if (classInfo?.slug) {
    sendAmplitudeData("CLASS_JOIN_BUTTON_CLICKED", {
      id: classInfo.id,
      slug: classInfo.slug,
      name: classInfo.name,
    })
  }
}

// ORDER PAGE
export const trackArrivedAtOrderPage = classInfo => {
  if (classInfo?.slug) {
    sendAmplitudeData("ORDER_CLASS_VIEWED", {
      id: classInfo.id,
      slug: classInfo.slug,
      name: classInfo.name,
    })
  }
}

export const trackErrorAtOrderPage = slug => {
  sendAmplitudeData("ORDER_CLASS_PAGE_ERROR", {
    slug: slug,
  })
}

// CREATE CLASS PAGE
export const trackCreateClass = () => {
  sendAmplitudeData("CREATE_CLASS_VIEWED")
}

// EDIT PROFIL PAGE
export const trackEditProfile = () => {
  sendAmplitudeData("EDIT_PROFIL_VIEWED")
}

// LANDING PAGE
export const trackLandingPageViewed = () => {
  sendAmplitudeData("LANDING_PAGE_VIEWED")
}

export const trackExploreClassesButtonClicked = () => {
  sendAmplitudeData("EXPLORE_CASSES_LANDING_BTN_CLICKED")
}

// TAMAN SISWA BACKGROUND PAGE
export const trackTamanSiswaBackground = () => {
  sendAmplitudeData("TAMAN_SISWA_BACKGROUND_VIEWED")
}

// NAVBAR
export const trackNavbarButtonClicked = name => {
  sendAmplitudeData(`NAVBAR_MENU_${name}_CLICKED`)
}
