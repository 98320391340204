import {
  configureStore,
  getDefaultMiddleware,
  combineReducers,
} from "@reduxjs/toolkit"
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist"
import storage from "redux-persist/lib/storage"
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2"

import sessionReducer from "./slices/session"

const persistConfig = {
  key: "session",
  storage: storage,
  stateReconciler: autoMergeLevel2,
}

const middleware = [
  ...getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
]

const persistedReducer = combineReducers({
  session: persistReducer(persistConfig, sessionReducer),
})

export default function useStore() {
  const store = configureStore({
    reducer: persistedReducer,
    middleware,
    devTools: process.env.GATSBY_PRODUCTION !== "TRUE",
  })
  const persistor = persistStore(store)
  return { store, persistor }
}
