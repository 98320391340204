import React from "react"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"

import { setAuthToken } from "./src/services/auth"
import useStore from "./src/redux/store"

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
  const { store, persistor } = useStore()

  function setAuthTokenAfterPersist() {
    const { token } = store.getState().session
    setAuthToken(token)
  }

  return (
    <Provider store={store}>
      <PersistGate
        persistor={persistor}
        onBeforeLift={setAuthTokenAfterPersist}
      >
        {() => <>{element}</>}
      </PersistGate>
    </Provider>
  )
}
