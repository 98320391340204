import wrapWithProvider from "./wrap-with-provider"
export const wrapRootElement = wrapWithProvider

const addScript = url => {
  const script = document.createElement("script")
  script.src = url
  script.type = "text/javascript"
  script.dataset.clientKey = process.env.GATSBY_MIDTRANS_CLIENT
    ? process.env.GATSBY_MIDTRANS_CLIENT
    : "SB-Mid-client-r38rREEwctQ_XgmK"
  script.async = true
  document.body.appendChild(script)
}

export const onClientEntry = () => {
  window.onload = () => {
    if (process.env.GATSBY_PRODUCTION === "TRUE") {
      addScript("https://app.midtrans.com/snap/snap.js")
    } else {
      addScript("https://app.sandbox.midtrans.com/snap/snap.js")
    }
  }
}
